<template>
    <div>
        {{ cellText }}
    </div>
</template>

<script>
import { priceFormatter } from '@/utils'

export default {
    props: {
        text: {
            type: [String, Number, Boolean, Object]
        },
        record: {
            type: Object
        },
        column: {
            type: Object,
            required: true
        }
    },
    computed: {
        cellText() {
            if(this.column.key === 'mustpaid')
                return this.priceFormatter(this.text)
            if(this.column.key === 'amount') {
                let cellText = this.priceFormatter(this.text) 
                if(this.record.currency)
                    cellText += ' ' + this.record.currency.icon
                
                return cellText
            }
            return this.priceFormatter(this.text)
        }
    },
    methods: {
        priceFormatter
    }
}
</script>